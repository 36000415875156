.banner{
    background-color: get-color(primary, 1);
    color: get-color(light, 1);
    padding: 30px;
    span::after{
        content: ">";
        color: get-color(primary, 2);
        padding: 0px 30px;
        
    }
    span:last-child::after{
        content: "";
        padding: 0;
    }
}