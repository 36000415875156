.hero {

	.section-inner {
		padding-top: $hero--padding-t__mobile;
		padding-bottom: $hero--padding-b__mobile;
	}
}

.hero-inner {

	// Affects only hero full
	>.hero-content+.hero-figure,
	>.hero-figure+.hero-content {
		margin-top: $hero--inner-padding-v_mobile;
	}

	>.hero-figure {

		>a {
			display: inline-flex;
			// prevents stretching
			align-items: center;
			vertical-align: top;
		}
	}
}

@include media('<=medium') {

	.hero {

		.split-wrap {

			.split-item {

				.split-item-content {
					margin-bottom: $hero--inner-padding-v_mobile;
				}
			}

			&.invert-mobile {

				.split-item {

					.split-item-image {
						margin-bottom: $hero--inner-padding-v_mobile;
					}
				}
			}
		}
	}
}

@include media('>medium') {

	.hero {

		.section-inner {
			padding-top: $hero--padding-t__desktop;
			padding-bottom: $hero--padding-b__desktop;
		}
	}

	.hero-inner {

		// Affects only hero full
		>.hero-content+.hero-figure,
		>.hero-figure+.hero-content {
			margin-top: $hero--inner-padding-v_desktop;
		}
	}
}

.download-button {
	>a.button>img {
		margin-right: 10px;
		margin-left: 0px;
	}
}
